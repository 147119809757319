import { useNavigateSearch } from "../../services/useNavigateSearch";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import EditOutlined from "@mui/icons-material/EditOutlined";
import CalendarMonthOutlined from "@mui/icons-material/CalendarMonthOutlined";
import * as routes from "../../constants/routes";

export const BookingPageListItem = ({ bookingPage }) => {
  const navigateSearch = useNavigateSearch();
  const goToViewBookings = () =>
    navigateSearch(`/${routes.bookingsPath}`, [
      [routes.bookingsProfileIdSearchParam, bookingPage.profileId],
    ]);
  const goToEditSettings = () =>
    navigateSearch(`/${routes.editProfilePath}`, [
      [routes.editProfileIdSearchParam, bookingPage.profileId],
    ]);
  return (
    <Paper
      sx={{
        p: 1,
        mb: 1,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Stack>
          <Link component="button" underline="none" onClick={goToEditSettings}>
            <Typography variant="body1">{bookingPage.title}</Typography>
          </Link>
          <Link href={`/${bookingPage.link}`} underline="none" target="_blank">
            {bookingPage.link}
          </Link>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button
            onClick={goToViewBookings}
            variant="text"
            startIcon={<CalendarMonthOutlined />}
          >
            View bookings
          </Button>
          <Button
            onClick={goToEditSettings}
            variant="text"
            startIcon={<EditOutlined />}
          >
            Edit settings
          </Button>
        </Stack>
      </Box>
    </Paper>
  );
};
